import React from "react"

//Utils
import Helmet from "react-helmet"
import Seo from "components/seo"
import { AsyncApollo, F_Q_CATALOGS, Q_HOME_CONTENT } from "lib/functions"
import { TokenValidation } from "lib/utils/token-validation"
import { ErrorHandlingPage } from "lib/utils/general-utils"

//Components
import Layout from "components/layout"
import HomePageComponents from "components/pages/home"
import { useRouter } from "next/router"
import { toastHandling } from "lib/utils/toast-handling"
import Cookies from "js-cookie"
import { CheckGeolocation } from "lib/utils/general-utils"

const HomePage = ({ ...props }) => {
  const Router = useRouter()
  React.useEffect(() => {
    if (Router.query?.verify && !Cookies.get("show_toast_verified")) {
      setTimeout(() => {
        toastHandling({
          message: "Your account has been verified",
          status: "success",
        })
        Cookies.set("show_toast_verified", true)
      }, 1000)
    }
  }, [Router])

  return (
    <>
      <Seo title="Home" />
      <Helmet>
        <body className="bd-home" />
      </Helmet>
      <Layout withBg="light">
        <HomePageComponents {...props} />
      </Layout>
    </>
  )
}

export default HomePage

export const getServerSideProps = async (ctx) => {
  let { token, user_status } = await TokenValidation(ctx)
  if (token) {
    let currency = await CheckGeolocation(ctx)
    let homeContent = await AsyncApollo(
      {
        query: Q_HOME_CONTENT,
        variables: { lang: ctx.locale },
      },
      token
    )
    if (homeContent?.data)
      return {
        props: {
          currency,
          collections: homeContent?.data?.collections,
        },
      }
    else {
      return ErrorHandlingPage(homeContent?.errors)
    }
  }
  return ErrorHandlingPage(null)
}
