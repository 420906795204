import { Button, Image } from "components/anti"
import { useScrollAnim, useWindowSize } from "components/hooks/hooks"
import { dataLayerEvent } from "lib/functions/datalayer-v2"

const HomeCover = ({ collections, categories }) => {
  const [trigger, anim] = useScrollAnim()
  const { width } = useWindowSize()
  const menId = categories?.find((item) => item.slug === "men")?.id
  const womenId = categories?.find((item) => item.slug === "women")?.id
  return (
    <section className="sc-home-cover" ref={trigger}>
      <div className={width > 576 ? "container" : ""}>
        <div className={`row row-collections`}>
          {collections?.map((item, i) => (
            <div className="col-md-6" key={`collection-item-${i}`}>
              <div className="items-wrapper">
                <div className={`overlay ${anim(i + 8)}`}></div>
                <Image
                  src={item.image}
                  className={`img-collections ${anim(
                    i + 2,
                    width < 576 ? "revealBlockInUp" : "revealInUp"
                  )}`}
                  alt={item.title}
                  ratio="r-3-2"
                  // useLoading
                />
                <div className={`buttons ${anim(i + 12)}`}>
                  {item?.slug?.includes("dwp") ? (
                    <Button
                      variant="outline-white"
                      link="/dwp"
                      className="dwp-btn"
                      onClick={() => {
                        dataLayerEvent.selectContent({
                          content_name: "#DWPXV Collection",
                          content_section: "Collection",
                        })
                      }}
                    >
                      View #DWPXV Collection
                    </Button>
                  ) : (
                    <>
                      <Button
                        variant="outline-white"
                        link={`/shop/men-${menId}?collection_id=${item.id}`}
                        onClick={() => {
                          dataLayerEvent.selectContent({
                            content_name: `${item.title} - Shop For Men`,
                            content_section: "Collection",
                          })
                        }}
                      >
                        SHOP FOR MEN
                      </Button>
                      <Button
                        variant="outline-white"
                        link={`/shop/women-${womenId}?collection_id=${item.id}`}
                        onClick={() => {
                          dataLayerEvent.selectContent({
                            content_name: `${item.title} - Shop For Women`,
                            content_section: "Collection",
                          })
                        }}
                      >
                        SHOP FOR WOMEN
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default HomeCover

// import React, { useRef, useState } from "react"

// //Assets

// //Components
// import { useScrollAnim } from "components/hooks/hooks"
// import { Cover, Button, Link, Slider } from "components/anti"

// const HomeCover = ({ banners }) => {
//   const sliderRef = useRef()
//   const [trigger, anim] = useScrollAnim()
//   const [activeDot, setActiveDot] = useState(0)

//   const handleDots = (index) => {
//     sliderRef.current.slickGoTo(index)
//   }
//   const customDotComp = () => (
//     <div className="custom_dot">
//       {banners?.length > 0 &&
//         banners?.map((item, i) => (
//           <div
//             key={`${item.id}-banners`}
//             className={`dot mr-2 cursor-pointer ${
//               activeDot === i && "active-dot"
//             }`}
//             onClick={() => {
//               handleDots(i)
//             }}
//           ></div>
//         ))}
//     </div>
//   )

//   return (
//     <section className="sc-home-cover" ref={trigger}>
//       <Slider
//         forwardRef={(slider) => (sliderRef.current = slider)}
//         beforeChange={(cur, nx) => setActiveDot(nx)}
//         infinite={true}
//         fade={true}
//         autoplay={true}
//         autoplaySpeed={4000}
//         dotsInitial={true}
//         arrowsInitial={false}
//         showInitial={"1"}
//         scrollInitial={1}
//       >
//         {banners?.length > 0 &&
//           banners?.map((item, i) => (
//             <div key={`${i}-banners${i}`}>
//               <div
//                 className="container wrapcover"
//                 style={{
//                   backgroundImage: `url(${item.desktop})`,
//                 }}
//               >
//                 <div className="wrapper-dot">{customDotComp()}</div>
//               </div>
//             </div>
//           ))}
//       </Slider>
//     </section>
//   )
// }

// export default HomeCover
